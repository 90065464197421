import { IBaseModel } from './base-model';
import { Location } from 'phoenix-common';

export class JobPosting implements IBaseModel {
  public postingId: string;
  public clientId: string;
  public address: string;
  public vendorId: string;
  public title: string;
  public description: string;
  public createdAt: Date;
  public completedAt: Date;
  public updatedAt: Date;
  public budgetRangeHigh: number;
  public budgetRangeLow: number;
  public distance: number;
  public expirationDate: string;
  public startDate: Date;
  public endDate: Date;
  public clientLocations: Array<Location> = [];
  public trades: Array<any> = [];
  public types: Array<any> = [];
  public isBidding: boolean;
  public isAwarded: boolean;
  public conversationId: number;
  public client: any;
  public lat: number;
  public long: number;
  public requirementCategoryId: number;
  public attachments: {fileName: string, attachmentUrl: string}[];

  public fromJSON(json: any): JobPosting {
    this.postingId = json['postingId'];
    this.clientId = json['clientId'];
    this.address = json['address'];
    this.vendorId = json['vendorId'];
    this.title = json['title'];
    this.description = json['description'];
    this.createdAt = json['createdAt'] ? new Date(json['createdAt']) : null;
    this.completedAt = json['completedAt'] ? new Date(json['completedAt']) : null;
    this.updatedAt = json['updatedAt'] ? new Date(json['updatedAt']) : null;
    this.budgetRangeHigh = json['budgetRangeHigh'];
    this.budgetRangeLow = json['budgetRangeLow'];
    this.distance = json['distance'];
    this.expirationDate = json['expirationDate'];
    this.startDate = json['startDate'];
    this.endDate = json['endDate'];
    this.isBidding = json['bidding'];
    this.conversationId = json['conversationId'];
    this.client = json['client'];
    this.lat = json['lat'];
    this.long = json['long'];

    if (json['jobTrades']) {
      json['jobTrades'].forEach(el => {
        this.trades.push(el);
      });
    }
    if (json['jobTypes']) {
      json['jobTypes'].forEach(el => {
        this.types.push(el['jobTypeId']);
      });
    }
    if (json['jobLocations']) {
      json['jobLocations'].forEach(el => {
        this.clientLocations.push(new Location().fromJSON(el['clientLocation']));
      });
    }

    return this;
  }

  public listFromJson(json: any): Array<JobPosting> {
    return json == null ? new Array<JobPosting>() : json.map((value) => new JobPosting().fromJSON(value));
  }
}
