import { IBaseModel } from "@vapp/models/base-model";

enum JobStates {
    ACTIVE,
    INACTIVE,
    PENDING_REGISTRATION,
}

class JobState implements IBaseModel {
    jobId: number;
    jobState: string;
    jobStateEnum: JobStates;

    public fromJSON(json: any) {
        if (json == null) return;

        this.jobId = json['jobId'];
        this.jobState = json['jobState'];

        this.jobStateEnum = JobStates[this.jobState];
        return this;
    }

    public listFromJson(json): Array<JobState> {
        return json == null ? new Array<JobState>() : json.map((value) => new JobState().fromJSON(value));
    }
}

export class JobsStatesResponse {
    jobStates: Array<JobState>;

    public fromJSON(json: any) {
        if (json == null) return;

        this.jobStates = new JobState().listFromJson(json['jobStates']);
        return this;
    }

    isJobActive(id: number): boolean {
        return this.jobStates.find((job) => job.jobId == id)?.jobStateEnum == JobStates.ACTIVE;
    }

    getJobState(id: number): JobState {
        return this.jobStates.find((job) => job.jobId == id);
    }

    getJobsIds(states: Array<JobStates>): Array<number> {
        return this.jobStates.filter((e) => states.includes(e.jobStateEnum)).map((e) => e.jobId);
    }

    getJobsCount(states: Array<JobStates>): number {
        return this.getJobsIds(states).length;
    }

    public listFromJson(json): Array<JobsStatesResponse> {
        return json == null ? new Array<JobsStatesResponse>() : json.map((value) => new JobsStatesResponse().fromJSON(value));
    }
}

