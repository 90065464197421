import { IBaseModel } from './base-model';

export class VendorPreference implements IBaseModel {

    public id: string;
    public name: string;
    public type: string;
    public value: boolean; // more supported value types can be added

    public fromJSON(json: any): VendorPreference {
        if (json == null) return;

        this.id = json["id"];
        this.name = json["name"];
        this.type = json["type"];
        switch (this.type) {
            case 'bool':
                this.value = (json["value"] === 'true');
                break;
            default:
                this.value = json["value"];
                break;
        }

        return this;
    }

    public listFromJson(json: any): Array<VendorPreference> {
        return json == null ? new Array<VendorPreference>() : json.map((value) => new VendorPreference().fromJSON(value));
    }
}
