import { NgxSliderModule } from 'ngx-slider-v2';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AppIdentifier, PhoenixCommonModule } from 'phoenix-common';
import { ClientCardComponent } from './client-card/client-card.component';
import { GoogleMapComponent } from './google-map/google-map.component';
import { PostingDetailsModalComponent } from '@vapp/modules/common/posting-details-modal/posting-details-modal.component';
import { PhoenixVerticalSteperComponent } from './phoenix-vertical-steper/phoenix-vertical-steper.component';
import { PhoenixProgressBarComponent } from './phx-progressbar/phoenix-progressbar.component';
import { MetersToMilesPipe } from './pipes/meters-miles-pipe/meters-miles.pipe';
import { SafePipe } from './pipes/safe-pipe/safe.pipe';
import { RecentPostingComponent } from '@vapp/modules/common/recent-postings/recent-posting.component';
import { RequestReviewsModalComponent } from './request-reviews-modal/request-reviews-modal.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [
    MetersToMilesPipe,
    SafePipe,
    PhoenixVerticalSteperComponent,
    PhoenixProgressBarComponent,
    PostingDetailsModalComponent,
    GoogleMapComponent,
    RecentPostingComponent,
    RequestReviewsModalComponent,
    ClientCardComponent
  ],
    imports: [
        FontAwesomeModule,
        CommonModule,
        ModalModule,
        FormsModule,
        TypeaheadModule.forRoot(),
        RatingModule,
        CollapseModule.forRoot(),
        PaginationModule.forRoot(),
        NgxSliderModule,
        ProgressbarModule.forRoot(),
        NgSelectModule,
        ReactiveFormsModule,
        PopoverModule.forRoot(),
        TooltipModule.forRoot(),
        PhoenixCommonModule,
        GalleryModule,
        LightboxModule,
        BsDatepickerModule.forRoot(),
        NgOptimizedImage
    ],
  providers: [BsModalService, { provide: 'appIdentifier', useValue: AppIdentifier.VENDOR }],
  exports: [
    CollapseModule,
    RatingModule,
    FontAwesomeModule,
    PaginationModule,
    NgxSliderModule,
    ProgressbarModule,
    NgSelectModule,
    MetersToMilesPipe,
    SafePipe,
    FormsModule,
    ReactiveFormsModule,
    PopoverModule,
    TooltipModule,
    CommonModule,
    TypeaheadModule,
    ModalModule,
    PhoenixCommonModule,
    PhoenixVerticalSteperComponent,
    PhoenixProgressBarComponent,
    GalleryModule,
    LightboxModule,
    PostingDetailsModalComponent,
    GoogleMapComponent,
    RecentPostingComponent,
    RequestReviewsModalComponent,
    ClientCardComponent,
    BsDatepickerModule
  ]
})
export class VendorWebappCommonModule { }
