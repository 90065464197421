export class GlobalConstants {
    public static readonly ALLOWED_FILE_TYPES = ['text/plain', 'image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
    public static readonly BCS_SUPPORT_EMAIL = 'vendorsuccess@bcsaudit.com';
    public static readonly INVITE_DEFAULT_NOTE = `Hi there,

I’d like to add you to my vendors and use your services. Please accept this invitation so you can submit your evidence of insurance for compliance purposes.

Thanks`;
    public static readonly BCS_WEBPAGE = 'https://getbcs.com';

    public static readonly VENDOR_CREDENTIALS_OBJ = {
        'NY_CERTIFIED': {
            name: 'BCS Certified for New York',
            icon_class: 'img-BCS'
        },
        'VERIFIED_LICENSES': {
            name: 'Verified - Licenses',
            icon_class: 'img-licenses'
        },
        'SCREENED_FINANCIAL': {
            name: 'Screened - Financial',
            icon_class: 'img-financial'
        },
        'SCREENED_BACKGROUND_CHECK': {
            name: 'Screened - Background Check',
            icon_class: 'img-backgroundCheck'
        }
    };
}

export enum PostingQueryTypes {
  RECENT_POSTINGS = 1,
  POSTINGS_FOR_VENDORS = 2,
  ALL_PUBLIC_POSTINGS = 3
}
