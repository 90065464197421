import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ColumnType, IColumnStruct } from 'phoenix-common';
import { PhoenixWebService } from '@vapp/providers/web.service';

interface PolicyTemplateData {
  name: string;
  limitNames: string[];
  policyLimits: number[];
}

interface Document {
  documentTypeName: string;
  required: boolean;
}

@Component({
  selector: 'vendor-view-requirements-modal',
  templateUrl: './view-requirements-modal.component.html',
  styleUrls: ['./view-requirements-modal.component.scss']
})
export class ViewRequirementsModalComponent implements OnInit {
  requirementId: number;
  loaded: boolean = false;
  insuranceRequirementCategoryName: string;
  insuranceRequirementCategoryDescription: string;
  protected requirement: any;
  protected requirementPolicies: Array<PolicyTemplateData> = [];
  protected documents: Document[] = [];

  protected requirementsColumns: Array<IColumnStruct> = [
    {
      title: 'Policies',
      variable: 'name'
    },
    {
      title: 'Limits',
      variable: 'limitNames',
      type: ColumnType.Rows
    },
    {
      title: 'Limit Amounts',
      variable: 'policyLimits',
      type: ColumnType.RowsMoney
    }
  ];

  protected documentsColumns: Array<IColumnStruct> = [
    {
      title: 'Document',
      variable: 'documentTypeName'
    },
    {
      title: 'Required',
      variable: 'required',
      type: ColumnType.CheckBox
    }
  ];

  constructor(protected phoenixWebService: PhoenixWebService,
              protected bsModalRef: BsModalRef) {
  }

  async ngOnInit(): Promise<void> {
    await this.init();
  }

  protected async init() {
    let result: any;
    try {
      result = await this.phoenixWebService.getRequirementDataByReqId(this.requirementId);
    }
    catch (err) {
      console.log(err);
    }
    this.insuranceRequirementCategoryName = result?.categoryName;
    this.insuranceRequirementCategoryDescription = result?.description;
    this.requirementPolicies = result?.requirementPolicyTemplates.map(p => {
      const policyNames = p.policyLimits.map(l => l.label);
      const limitAmounts = p.policyLimits.map(l => l.reqAmount);
      return {
        name: p.name,
        policyLimits: limitAmounts,
        limitNames: policyNames
      };
    });
    this.documents = result?.documents?.map(d => {
      return {
        documentTypeName: d.description,
        required: d.deficiencyStatusDescription == 'Major'
      };
    });
    this.loaded = true;
  }
}
