import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { JobPosting } from '@vapp/models/job-posting-model';

@Component({
  selector: 'vendor-google-map',
  templateUrl: 'google-map.component.html'
})

export class GoogleMapComponent implements OnChanges, AfterViewInit {
  @ViewChild('map') mapContainer: ElementRef;
  @ViewChild('searchMap') searchMap: ElementRef;
  @Input() haveButton = false;
  @Input() jobPostings: Array<JobPosting> = [];
  @Input() postingHovered: {
    posting: any,
    leave: boolean
  };
  @Output() markerHovered: EventEmitter<{
    posting: any,
    leave: boolean
  }> = new EventEmitter<{
    posting: JobPosting,
    leave: boolean
  }>();
  @Output() markerClicked: EventEmitter<JobPosting> = new EventEmitter<JobPosting>();
  public markers: Array<google.maps.Marker> = [];
  public markersMap: any = {};
  public map: google.maps.Map;
  public mapOptions: google.maps.MapOptions  = {
    center: {
      lat: 40.70631559451961,
      lng: -73.62057406936488
    },
    fullscreenControl: false,
    zoomControl: false,
    disableDefaultUI: true,
    zoom: 13,
    minZoom: 3,
    maxZoom: 17,
    mapTypeId: 'roadmap'
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['jobPostings']) {
      this.setMapDefaultOptions();
      this.renderMarkers();
    }

    if (changes['postingHovered']) {
      const event = changes['postingHovered'].currentValue;
      const postingMarker = this.markersMap[event?.posting?.postingId];
      if (postingMarker) {
        if (event.leave) {
          this.markerHoverLeave(event.posting);
        } else {
          this.markerHover(postingMarker, event.posting);
        }
      }
    }
  }

  ngAfterViewInit() {
    this.setMapDefaultOptions();
  }

  renderMarkers() {
    for (const marker of this.markers) {
      marker.setMap(this.map);
    }
    this.markers = [];
    this.markersMap = {};
    for (const posting of this.jobPostings) {
      if (posting.lat && posting.long) {
        const options = {
          position: {
            lat: posting.lat,
            lng: posting.long
          }
        };
        const mapMarker = new google.maps.Marker(options);
        mapMarker.addListener('mouseover', () => this.markerHover(mapMarker, posting));
        mapMarker.addListener('mouseout', () => this.markerHoverLeave(posting));
        mapMarker.addListener('click', () => this.markerClick(posting));
        this.markersMap[posting.postingId] = mapMarker;
        this.markers.push(mapMarker);
        mapMarker.setMap(this.map);
      }
    }
  }

  markerHover(marker: google.maps.Marker,
              posting: JobPosting) {
    if ((!this.map.getBounds().contains(marker.getPosition()))) {
      this.map.setCenter(marker.getPosition());
    }
    this.markerHovered.next({
      posting,
      leave: false
    });
  }

  markerHoverLeave(posting: JobPosting) {
    this.markerHovered.next({
      posting,
      leave: true
    });
  }

  markerClick(posting: JobPosting) {
    this.markerClicked.next(posting);
  }

  private setMapDefaultOptions() {
    let lat = 40.70631559451961;
    let lng = -73.62057406936488;
    if (this.jobPostings.length > 0) {
      lat = this.jobPostings[0].lat;
      lng = this.jobPostings[0].long;
    }
    this.mapOptions.center = {
      lat,
      lng
    };
    this.initMap();
  }

  private initMap() {
    if (this?.mapContainer?.nativeElement) {
      this.map = new google.maps.Map(this.mapContainer.nativeElement, this.mapOptions);
      if (this.haveButton) {
        this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(this.searchMap.nativeElement);
      }
      this.renderMarkers();
    }
  }
}
