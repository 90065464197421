<div (click)="openPostingDetails(posting)" [class.hover]="hover" class="recent-posting-item">
  <div class="posting-image-container">
    <img [ngSrc]="posting?.client?.clientLogo" alt="profile-placeholder.png" height="150" width="150" />
  </div>
  <div class="posting-info-container">
    <div class="posting-dates-container">
      <div class="date-container">
        <label for="project-starts">Starts:</label>
        <div id="project-starts">{{ posting?.startDate | date }}</div>
      </div>
      <div class="date-container">
        <label for="project-ends">Ends:</label>
        <div id="project-ends">{{ posting?.endDate | date }}</div>
      </div>
      <div class="date-container">
        <label for="posting-expires">Expires:</label>
        <div id="posting-expires">{{ posting?.expirationDate | date }}</div>
      </div>
      <div *ngIf="posting?.isBidding" class="posting-type-container">
        <div class="bidding-label"
             data-id="companyRecentPostingBidding">
          Bidding
        </div>
      </div>
      <div *ngIf="posting?.isAwarded" class="posting-type-container">
        <div class="awarded-label"
             data-id="companyRecentPostingAwarded">
          Awarded
        </div>
      </div>
    </div>
    <div class="main-content-container">
      <div class="label-container">
        <label for="client-name">Client Name:</label>
        <div [title]="posting?.client?.name" class="text" id="client-name"><b>{{ posting?.client?.name }}</b></div>
      </div>
      <div class="label-container">
        <label for="posting-title">Posting Title:</label>
        <div [title]="posting?.title" class="text" id="posting-title"><b>{{ posting?.title }}</b></div>
      </div>
      <div class="label-container">
        <label for="budget">Budget:</label>
        <div class="description" id="budget">{{ getBudgetRangeLow() + '-' + getBudgetRangeHigh() }}</div>
      </div>
      <div class="label-container">
        <label for="address">Nearest Address:</label>
        <div id="address" class="text">{{ getPostingClientAddress() }}</div>
      </div>
    </div>
  </div>
</div>
<br />
